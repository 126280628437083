import React from 'react'
import FullWidthTabs from '../commons/FullWidthTabs'

function Categorization() {
  return (
    <div className='ml-[240px]  p-8 min-h-screen'>
        <div className='w-full '>
        <div className="flex justify-start mb-4">
                <h2 className="text-lg font-semibold">Categorization Options</h2>
            </div>
        <FullWidthTabs />
        </div>
    </div>
  )
}

export default Categorization