import React from 'react';

export default function Authorization() {
  return (
    <div className="md:ml-[240px] p-5">
      <h2 className="text-2xl text-start font-semibold mb-10">Edit Authorization Information</h2>
      
      <form>
        {/* Registration Type */}
        <div className="mb-6 flex">
          <label className="block text-start text-gray-700 font-medium mb-2 min-w-[290px]" style={{fontSize:"12px"}} htmlFor="registrationType">
          Security Plan Approval Status
          </label>
          <select 
            id="registrationType" 
            className="w-full border border-gray-300 p-3 rounded-lg bg-gray-50 text-gray-800 focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          >
            <option value=""></option>
            {/* Add options here */}
          </select>
        </div>

        {/* System Name */}
        <div className="mb-6 flex">
          <label className="block text-start text-gray-700 font-medium mb-2 min-w-[290px]" style={{fontSize:"12px"}} htmlFor="systemName">
          Security Plan Approval Date: (MM/DD/YYYY)
          </label>
          <input 
            type="text" 
            id="systemName" 
            className="w-full border border-gray-300 p-3 rounded-lg bg-gray-50 text-gray-800 focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          />
        </div>

     

        {/* System Type */}
        <div className="mb-6 flex">
          <label className="block text-start text-gray-700 font-medium mb-2 min-w-[290px]" style={{fontSize:"12px"}} htmlFor="systemType">
          Authorization Status:
          </label>
          <select 
            id="systemType" 
            className="w-full border border-gray-300 p-3 rounded-lg bg-gray-50 text-gray-800 focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          >
            <option value="">Not yet authorized</option>
            {/* Add options here */}
          </select>
        </div>
        <div className="mb-6 flex">
          <label className="block text-start text-gray-700 font-medium mb-2 min-w-[290px]" style={{fontSize:"12px"}} htmlFor="systemName">
          Assessment Completion Date: (MM/DD/YYYY)
          </label>
          <input 
            type="text" 
            id="systemName" 
            className="w-full border border-gray-300 p-3 rounded-lg bg-gray-50 text-gray-800 focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          />
        </div>
        <div className="mb-6 flex">
          <label className="block text-start text-gray-700 font-medium mb-2 min-w-[290px]" style={{fontSize:"12px"}} htmlFor="systemName">
          Authorization Date: (MM/DD/YYYY)
          </label>
          <input 
            type="text" 
            id="systemName" 
            className="w-full border border-gray-300 p-3 rounded-lg bg-gray-50 text-gray-800 focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          />
        </div>
        <div className="mb-6 flex">
          <label className="block text-start text-gray-700 font-medium mb-2 min-w-[290px]" style={{fontSize:"12px"}} htmlFor="systemName">
          Authorization Termination Date (MM/DD/YYYY)

          </label>
          <input 
            type="text" 
            id="systemName" 
            className="w-full border border-gray-300 p-3 rounded-lg bg-gray-50 text-gray-800 focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          />
        </div>

        
        <div className="mb-6 flex">
          <label className="block text-start text-gray-700 font-medium mb-2 min-w-[290px]" style={{fontSize:"12px"}} htmlFor="systemType">
          RMF Activity:
          </label>
          <select 
            id="systemType" 
            className="w-full border border-gray-300 p-3 rounded-lg bg-gray-50 text-gray-800 focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            >
            <option value=""></option>
            {/* Add options here */}
          </select>
        </div>

        {/* System Description */}
        <div className="mb-6 flex">
          <label className="block text-start text-gray-700 font-medium mb-2 min-w-[290px]" style={{fontSize:"12px"}} htmlFor="systemDescription">
            Terms/Conditions for Authorization
          </label>
          <textarea 
            id="systemDescription" 
            rows="4" 
            className="w-full border border-gray-300 p-3 rounded-lg bg-gray-50 text-gray-800 focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          ></textarea>
        </div>
        <div className='flex justify-between my-16'>
          <button className='w-32 py-2  bg-white border-2 text-gray-800 rounded-full '>Cancel</button>
          <button className='w-32 py-2  bg-gray-800 text-white rounded-full '>Save</button>
        </div>
      </form>
    </div>
  );
}
