// Welcome.js
import React from 'react';
import Sidebar2 from './Sidebar2';


function Welcome() {
    return (
        <>
        <Sidebar2 />
        <div className="md:ml-[240px] p-5">
            <div class="bg-white p-4  w-full h-[60vh] flex flex-col mx-auto justify-center max-w-3xl ">
                <h2 class="text-2xl font-bold mb-2">Announcements</h2>
                <p class="text-sm text-gray-600 mb-4">19-July-2024 Announcement expires 19-July-2024</p>
                <p class="text-base mb-4">
                    An Authorized Service Interruption (ASI) for server maintenance is scheduled,
                    19 July, 2024 from 0900-1500 ET (1400-2000 UTC).
                </p>
                <p class="text-base mb-4 ">
                    All GRC Platform instances will be inaccessible, and any data and/or information entered will not be saved during the ASI.
                </p>
                <p class="text-base mb-4 ">V/r, eMASS Support Team</p>
            </div>
        </div>
        </>
    );
}

export default Welcome;
