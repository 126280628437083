import * as React from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import ControlSelection from '../components/ControlSelection';
import AvailableOverlays from '../components/AvailableOverlays';
import ManageSecurityControls from '../components/ManageSecurityControls';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function FullWidthTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ bgcolor: '#1f2937', color: 'white' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          centered
          textColor="inherit" // Inherit the color from the parent Box
          TabIndicatorProps={{ style: { backgroundColor: 'white' ,fontWeight:'700'} }} // Active tab underline color
        >
          <Tab label="Control Selection" sx={{ fontWeight: 'bold' }}  />
          <Tab label="Overlays"  sx={{ fontWeight: 'bold' }} />
          <Tab label="Manages" sx={{ fontWeight: 'bold' }}  />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
       <ControlSelection />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <AvailableOverlays />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <ManageSecurityControls />
      </TabPanel>
    </Box>
  );
}
