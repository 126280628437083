import React from 'react';

export default function ReviewAndSubmit() {
  return (
    <div className="md:ml-[240px] p-5">
      <h2 className="text-2xl text-start font-semibold mb-10">Review and Submit</h2>
      
      <div>
        <h3 className="text-xl text-start font-semibold mb-6">System Information</h3>

        {/* Registration Type */}
        <div className="mb-4 flex">
          <label className="block text-start text-gray-700 font-medium min-w-[290px]" style={{fontSize:"12px"}} > Registration Type:</label>
          <p className="text-gray-800">[Value]</p>
        </div>

        {/* System Name */}
        <div className="mb-4 flex">
          <label className="block text-start text-gray-700 font-medium min-w-[290px]" style={{fontSize:"12px"}} > System Name:</label>
          <p className="text-gray-800">Test</p>
        </div>

        {/* System Acronym */}
        <div className="mb-4 flex">
          <label className="block text-start text-gray-700 font-medium min-w-[290px]" style={{fontSize:"12px"}} > System Acronym:</label>
          <p className="text-gray-800">Test2</p>
        </div>

        {/* Information System Owner */}
        <div className="mb-4 flex">
          <label className="block text-start text-gray-700 font-medium min-w-[290px]" style={{fontSize:"12px"}} > Information System Owner:</label>
          <p className="text-gray-800">[Value]</p>
        </div>

        {/* Version / Release Number */}
        <div className="mb-4 flex">
          <label className="block text-start text-gray-700 font-medium min-w-[290px]" style={{fontSize:"12px"}} > Version / Release Number:</label>
          <p className="text-gray-800">[Value]</p>
        </div>

        {/* System Type */}
        <div className="mb-4 flex">
          <label className="block text-start text-gray-700 font-medium min-w-[290px]" style={{fontSize:"12px"}} > System Type:</label>
          <p className="text-gray-800">[Value]</p>
        </div>

        {/* Acquisition Category */}
        <div className="mb-4 flex">
          <label className="block text-start text-gray-700 font-medium min-w-[290px]" style={{fontSize:"12px"}} > Acquisition Category:</label>
          <p className="text-gray-800">[Value]</p>
        </div>

        {/* System Life Cycle / Acquisition Phase */}
        <div className="mb-4 flex">
          <label className="block text-start text-gray-700 font-medium min-w-[290px]" style={{fontSize:"12px"}} > System Life Cycle / Acquisition Phase:</label>
          <p className="text-gray-800">[Value]</p>
        </div>

        {/* National Security System */}
        <div className="mb-4 flex items-center">
          <label className="block text-start text-gray-700 font-medium min-w-[290px]" style={{fontSize:"12px"}} > National Security System:</label>
          <input type="checkbox" disabled className="form-checkbox text-blue-600" />
        </div>

        {/* Financial Management System */}
        <div className="mb-4 flex items-center">
          <label className="block text-start text-gray-700 font-medium min-w-[290px]" style={{fontSize:"12px"}} > Financial Management System:</label>
          <input type="checkbox" disabled className="form-checkbox text-blue-600" />
        </div>

        {/* Reciprocity System */}
        <div className="mb-4 flex items-center">
          <label className="block text-start text-gray-700 font-medium min-w-[290px]" style={{fontSize:"12px"}} > Reciprocity System:</label>
          <input type="checkbox" disabled checked className="form-checkbox text-blue-600" />
        </div>

        {/* System Description */}
        <div className="mb-4 flex">
          <label className="block text-start text-gray-700 font-medium min-w-[290px]" style={{fontSize:"12px"}} > System Description:</label>
          <p className="text-gray-800">[Value]</p>
        </div>

        {/* DITPR ID */}
        <div className="mb-4 flex">
          <label className="block text-start text-gray-700 font-medium min-w-[290px]" style={{fontSize:"12px"}} > DITPR ID:</label>
          <p className="text-gray-800">[Value]</p>
        </div>

        {/* DoD IT Registration Number */}
        <div className="mb-4 flex">
          <label className="block text-start text-gray-700 font-medium min-w-[290px]" style={{fontSize:"12px"}} > DoD IT Registration Number:</label>
          <p className="text-gray-800">[Value]</p>
        </div>
      </div>
      <div className='flex justify-between my-16'>
          <button className='w-32 py-2  bg-white border-2 text-gray-800 rounded-full '>Cancel</button>
          <button className='w-32 py-2  bg-gray-800 text-white rounded-full '>Submit</button>
        </div>
    </div>
  );
}
