import React, { useState, useRef, useEffect } from 'react';

const ControlListing = () => {
    // State to manage the open/close state of each accordion
    const [openSections, setOpenSections] = useState({});

    // Data for the accordion sections
    const controlSections = [
        {
            title: 'ACCESS CONTROL',
            acronym: 'AC-1',
            status: 'Not Applicable',
            name: 'ACCESS CONTROL POLICY AND PROCEDURES',
            properties: 'Baseline',
            residualRisk: 'Low',
        },
        {
            title: 'AWARENESS AND TRAINING',
            acronym: 'AT-1',
            status: 'Not Compliant',
            name: 'SECURITY AWARENESS AND TRAINING POLICY AND PROCEDURES',
            properties: 'Baseline',
            residualRisk: 'Unassigned',
        },
        {
            title: 'AUDIT AND ACCOUNTABILITY',
            acronym: 'AT-1',
            status: 'Not Compliant',
            name: 'SECURITY AWARENESS AND TRAINING POLICY AND PROCEDURES',
            properties: 'Baseline',
            residualRisk: 'Unassigned',
        },
        {
            title: 'SECURITY ASSESSMENT AND AUTHORIZATION',
            acronym: 'AT-1',
            status: 'Not Compliant',
            name: 'SECURITY AWARENESS AND TRAINING POLICY AND PROCEDURES',
            properties: 'Baseline',
            residualRisk: 'Unassigned',
        },
        {
            title: 'CONFIGURATION MANAGEMENT',
            acronym: 'AT-1',
            status: 'Not Compliant',
            name: 'SECURITY AWARENESS AND TRAINING POLICY AND PROCEDURES',
            properties: 'Baseline',
            residualRisk: 'Unassigned',
        },
        {
            title: 'CONTINGENCY PLANNING',
            acronym: 'AT-1',
            status: 'Not Compliant',
            name: 'SECURITY AWARENESS AND TRAINING POLICY AND PROCEDURES',
            properties: 'Baseline',
            residualRisk: 'Unassigned',
        },
        {
            title: ' IDENTIFICATION AND AUTHENTICATION',
            acronym: 'AT-1',
            status: 'Not Compliant',
            name: 'SECURITY AWARENESS AND TRAINING POLICY AND PROCEDURES',
            properties: 'Baseline',
            residualRisk: 'Unassigned',
        },
        // Add more sections as needed
    ];

    // Create an array of refs for each section
    const sectionRefs = useRef([]);

    useEffect(() => {
        // Initialize the refs array with one ref per control section
        sectionRefs.current = controlSections.map(
            (_, i) => sectionRefs.current[i] ?? React.createRef()
        );
    }, [controlSections.length]);

    // Function to toggle the open/close state of a section
    const toggleSection = (index) => {
        setOpenSections((prevState) => ({
            ...prevState,
            [index]: !prevState[index],
        }));
    };

    return (
        <div className="p-8 ml-[240px] min-h-screen">
            <h2 className="text-lg font-semibold text-left mb-4">Control Listing</h2>
            <div className="my-4 text-right">
                <span className="text-sm text-gray-500">
                    Showing {controlSections.length} entries
                </span>
            </div>
            <div className="grid grid-cols-4 gap-4">
                {/* Control Actions Sidebar */}
                <div className="col-span-1 bg-gray-100 rounded-lg shadow-md p-4 h-fit">
                    <h3 className="text-sm font-semibold mb-2">Control Actions</h3>
                    <button className="w-full text-sm text-blue-500 border border-blue-500 px-4 py-2 rounded mb-2">
                        Import/Export
                    </button>
                    <button className="w-full text-sm text-blue-500 border border-blue-500 px-4 py-2 rounded">
                        Bulk Processing
                    </button>
                </div>

                {/* Control Listing Content */}
                <div className="col-span-3 space-y-6">
                    {controlSections.map((section, index) => {
                        return (
                            <div key={index}>
                                <div
                                    className="bg-gray-800 text-white p-3 rounded-t-lg cursor-pointer"
                                    onClick={() => toggleSection(index)}
                                >
                                    {section.title}
                                </div>
                                <div
                                    ref={sectionRefs.current[index]}
                                    className={`bg-gray-100 rounded-b-lg shadow-md overflow-hidden transition-all duration-300`}
                                    style={{
                                        maxHeight: openSections[index]
                                            ? `${sectionRefs.current[index]?.current?.scrollHeight || 0}px`
                                            : '0px',
                                    }}
                                >
                                    <div className="p-4 bg-gray-100 border border-gray-200 rounded-lg">
                                        <table className="min-w-full">
                                            <thead>
                                                <tr>
                                                    <th className="px-4 py-2 text-center text-[12px] font-semibold text-gray-600">
                                                        Acronym
                                                    </th>
                                                    <th className="px-4 py-2 text-center text-[12px] font-semibold text-gray-600">
                                                        Status
                                                    </th>
                                                    <th className="px-4 py-2 text-center text-[12px] font-semibold text-gray-600">
                                                        Name
                                                    </th>
                                                    <th className="px-4 py-2 text-center text-[12px] font-semibold text-gray-600">
                                                        Properties
                                                    </th>
                                                    <th className="px-4 py-2 text-center text-[12px] font-semibold text-gray-600">
                                                        Residual Risk
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr className="border-t border-gray-200">
                                                    <td className="px-4 py-2 text-[12px] text-blue-500 font-semibold">
                                                        {section.acronym}
                                                    </td>
                                                    <td className="px-4 py-2 text-[12px]">
                                                        {section.status}
                                                    </td>
                                                    <td className="px-4 py-2 text-[12px]">
                                                        {section.name}
                                                    </td>
                                                    <td className="px-4 py-2 text-[12px]">
                                                        {section.properties}
                                                    </td>
                                                    <td className="px-4 py-2 text-[12px]">
                                                        {section.residualRisk}
                                                    </td>
                                                </tr>
                                                {/* Additional rows can be added here if needed */}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default ControlListing;
