import React from 'react';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { NavLink } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';  // Example icons
import AddIcon from '@mui/icons-material/Add';   
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';     // Example icons
import ReportIcon from '@mui/icons-material/Report';  // Example icons
import ImportExportOutlinedIcon from '@mui/icons-material/ImportExportOutlined';
import SecurityOutlinedIcon from '@mui/icons-material/SecurityOutlined';
import mylogo from '../assets/logo.png';
const drawerWidth = 240;
const Sidebar2 = ({ mobileOpen, handleDrawerToggle, isMobile }) => {
    const drawer = (
        <div>
             <img src={mylogo} alt="logo" className='w-[200px] mt-10 mx-auto' />
             <Toolbar />

            {/* Authorization Section */}
            <Box sx={{ p: 2 }}>
                <Typography variant="h6" gutterBottom sx={{ textAlign: 'left', pl: 0, ml: 0 }}>
                    Authorization
                </Typography>
                <List>
                    {[
                        { text: 'Search Systems', path: '/search', icon: <SearchIcon /> },


                        
                        { text: 'New System Registration', path: '/home/edit-system', icon: <AddIcon /> },
                        { text: 'Pending System Registration', path: '/pending-system', icon: <PendingOutlinedIcon/> },
                        { text: 'System Import', path: '/system-import', icon: <ImportExportOutlinedIcon/> },
                        { text: 'Template Import', path: '/template-import', icon: <ImportExportOutlinedIcon/> },
                        { text: 'Cybersecurity Content', path: '/cybersecurity-content', icon: <SecurityOutlinedIcon/>  }
                    ].map(({ text, path, icon }) => (
                        <ListItem key={text} disablePadding>
                            <ListItemButton component={NavLink} to={path} sx={{ pl: 1 }}
                                activeClassName="Mui-selected"
                                exact
                                style={({ isActive }) => ({
                                    background: isActive ? '#1f2937' : 'inherit',
                                    color: isActive ? '#fff' : 'inherit',
                                })}
                            >
                                <ListItemIcon sx={{ minWidth: '30px', fontSize: '4px', color:'inherit' }}>
                                    {icon} {/* Display the icon */}
                                </ListItemIcon>
                                <ListItemText
                                    primary={text}
                                    sx={{
                                        '& .MuiTypography-root': {
                                            fontSize: '12px'
                                        }
                                    }}
                                />

                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>

                <Divider sx={{ my: 2 }} />

                {/* Reports Section */}
                <Typography variant="h6" gutterBottom sx={{ textAlign: 'left', pl: 0, ml: 0 }}>
                    Reports
                </Typography>
                <List>
                    {[
                        { text: 'Executive Reports', path: '/executive-reports', icon: <ReportIcon /> },
                        { text: 'System Reports', path: '/system-reports', icon: <ReportIcon /> }
                    ].map(({ text, path, icon }) => (
                        <ListItem key={text} disablePadding>
                            <ListItemButton component={NavLink} to={path} sx={{ pl: 1 }}
                                activeClassName="Mui-selected"
                                exact
                                style={({ isActive }) => ({
                                    background: isActive ? '#1f2937' : 'inherit',
                                    color: isActive ? '#fff' : 'inherit',
                                })}
                            >
                                <ListItemIcon sx={{ minWidth: '30px' }}>
                                    {icon} {/* Display the icon */}
                                </ListItemIcon>
                                <ListItemText
                                    primary={text}
                                    sx={{
                                        '& .MuiTypography-root': {
                                            fontSize: '12px'
                                        }
                                    }}
                                />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </Box>
        </div>
    );

    return (
        <Drawer
            variant={isMobile ? 'temporary' : 'permanent'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
                keepMounted: true,
            }}
            sx={{
                display: { xs: 'block', sm: 'block' },
                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
        >
            {drawer}
        </Drawer>
    );
};

export default Sidebar2;
