import React from 'react';

export default function EditSystemInformation() {
  return (
    <div className="md:ml-[240px] p-5">
      <h2 className="text-2xl text-start font-semibold mb-10">Edit System Information</h2>
      
      <form>
        {/* Registration Type */}
        <div className="mb-6 flex">
          <label className="block text-start text-gray-700 font-medium  mb-2 min-w-[250px]" style={{fontSize:"12px"}} htmlFor="registrationType">
            Registration Type:
          </label>
          <select 
            id="registrationType" 
            className="w-full border border-gray-300 p-3 rounded-lg bg-gray-50 text-gray-800 focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          >
            <option value="">Select...</option>
            {/* Add options here */}
          </select>
        </div>

        {/* System Name */}
        <div className="mb-6 flex">
          <label className="block text-start text-gray-700 font-medium  mb-2 min-w-[250px]" style={{fontSize:"12px"}} htmlFor="systemName">
            System Name:
          </label>
          <input 
            type="text" 
            id="systemName" 
            className="w-full border border-gray-300 p-3 rounded-lg bg-gray-50 text-gray-800 focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          />
        </div>

        {/* System Acronym */}
        <div className="mb-6 flex">
          <label className="block text-start text-gray-700 font-medium  mb-2 min-w-[250px]" style={{fontSize:"12px"}} htmlFor="systemAcronym">
            System Acronym:
          </label>
          <input 
            type="text" 
            id="systemAcronym" 
            className="w-full border border-gray-300 p-3 rounded-lg bg-gray-50 text-gray-800 focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          />
        </div>

        {/* Information System Owner */}
        <div className="mb-6 flex">
          <label className="block text-start text-gray-700 font-medium  mb-2 min-w-[250px]" style={{fontSize:"12px"}} htmlFor="systemOwner">
            Information System Owner:
          </label>
          <input 
            type="text" 
            id="systemOwner" 
            className="w-full border border-gray-300 p-3 rounded-lg bg-gray-50 text-gray-800 focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          />
        </div>

        {/* Version / Release Number */}
        <div className="mb-6 flex">
          <label className="block text-start text-gray-700 font-medium  mb-2 min-w-[250px]" style={{fontSize:"12px"}} htmlFor="versionNumber">
            Version / Release Number:
          </label>
          <input 
            type="text" 
            id="versionNumber" 
            className="w-full border border-gray-300 p-3 rounded-lg bg-gray-50 text-gray-800 focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          />
        </div>

        {/* System Type */}
        <div className="mb-6 flex">
          <label className="block text-start text-gray-700 font-medium  mb-2 min-w-[250px]" style={{fontSize:"12px"}} htmlFor="systemType">
            System Type:
          </label>
          <select 
            id="systemType" 
            className="w-full border border-gray-300 p-3 rounded-lg bg-gray-50 text-gray-800 focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          >
            <option value="">Select...</option>
            {/* Add options here */}
          </select>
        </div>

        {/* Acquisition Category */}
        <div className="mb-6 flex">
          <label className="block text-start text-gray-700 font-medium  mb-2 min-w-[250px]" style={{fontSize:"12px"}} htmlFor="acquisitionCategory">
            Acquisition Category:
          </label>
          <select 
            id="acquisitionCategory" 
            className="w-full border border-gray-300 p-3 rounded-lg bg-gray-50 text-gray-800 focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          >
            <option value="">Select...</option>
            {/* Add options here */}
          </select>
        </div>

        {/* System Life Cycle / Acquisition Phase */}
        <div className="mb-6 flex">
          <label className="block text-start text-gray-700 font-medium  mb-2 min-w-[250px]" style={{fontSize:"12px"}} htmlFor="systemLifeCycle">
            System Life Cycle / Acquisition Phase:
          </label>
          <select 
            id="systemLifeCycle" 
            className="w-full border border-gray-300 p-3 rounded-lg bg-gray-50 text-gray-800 focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          >
            <option value="">Select...</option>
            {/* Add options here */}
          </select>
        </div>

        {/* National Security System */}
        <div className="mb-6 flex items-center">
          <label className="block text-start text-gray-700 font-medium  mb-2 min-w-[250px]" style={{fontSize:"12px"}} htmlFor="nationalSecuritySystem">
            National Security System:
          </label>
          <input 
            type="checkbox" 
            id="nationalSecuritySystem" 
            className="w-5 h-5 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500"
          />
        </div>

        {/* Financial Management System */}
        <div className="mb-6 flex items-center">
          <label className="block text-start text-gray-700 font-medium  mb-2 min-w-[250px]" style={{fontSize:"12px"}} htmlFor="financialManagementSystem">
            Financial Management System:
          </label>
          <input 
            type="checkbox" 
            id="financialManagementSystem" 
            className="w-5 h-5 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500"
          />
        </div>

        {/* Reciprocity System */}
        <div className="mb-6 flex items-center">
          <label className="block text-start text-gray-700 font-medium  mb-2 min-w-[250px]" style={{fontSize:"12px"}} htmlFor="reciprocitySystem">
            Reciprocity System:
          </label>
          <input 
            type="checkbox" 
            id="reciprocitySystem" 
            className="w-5 h-5 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500" 
            defaultChecked
          />
        </div>

        {/* System Description */}
        <div className="mb-6 flex">
          <label className="block text-start text-gray-700 font-medium  mb-2 min-w-[250px]" style={{fontSize:"12px"}} htmlFor="systemDescription">
            System Description:
          </label>
          <textarea 
            id="systemDescription" 
            rows="4" 
            className="w-full border border-gray-300 p-3 rounded-lg bg-gray-50 text-gray-800 focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          ></textarea>
        </div>
        <div className='flex justify-between my-16'>
          <button className='w-32 py-2  bg-white border-2 text-gray-800 rounded-full '>Cancel</button>
          <button className='w-32 py-2  bg-gray-800 text-white rounded-full '>Save</button>
        </div>
      </form>
    </div>
  );
}
