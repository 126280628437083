import React from 'react';
import { useNavigate } from 'react-router-dom';

const ControlSelection = () => {

    const navigate = useNavigate()
    return (
        <div className="py-2">
            {/* Control Selection */}
            <div className="mb-6">
                <h2 className="text-[14px] font-bold mb-6">Control Selection</h2>
                <div className="space-y-2">
                    {/* Applied Information Types */}
                    <div>
                        <h3 className="text-[14px] font-semibold mb-4">Applied Information Types(2)</h3>
                        <div className="bg-gray-100 p-4 rounded-lg">
                            <div className="text-blue-500 cursor-pointer text-right mb-4 text-[12px]" onClick={()=>navigate('/search/information-types')}>Edit Information</div>
                            <div className="flex justify-between items-center text-[12px]">
                                <div>Tax and Fiscal Policy</div>
                                <div className="flex space-x-6">
                                    <div>Confidentiality: Moderate</div>
                                    <div>Integrity: High</div>
                                    <div>Availability: Low</div>
                                </div>
                            </div>
                            <div className="flex justify-between items-center mt-2 text-[12px]">
                                <div>Central Fiscal Operations</div>
                                <div className="flex space-x-6">
                                    <div>Confidentiality: Moderate</div>
                                    <div>Integrity: Low</div>
                                    <div>Availability: Low</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Primary Security Control Set */}
            <div>
                <h3 className="text-[14px] font-semibold mb-4">Primary Security Control Set</h3>
                <div className="bg-gray-100 p-4 rounded-lg">
                    <div className="text-blue-500 cursor-pointer text-right text-[12px]" onClick={()=>{navigate('/search/primary-security')}}>Edit Control Set</div>
                    <div className="my-4">
                        <div className="flex justify-between items-center text-[12px]">
                            <div>Primary Security Control Set:</div>
                            <div>NIST SP 800-53 Revision 4</div>
                        </div>
                    </div>
                    <div className="space-y-2">
                        <div className="font-semibold t mb-4 text-[12px]">Control Attributes</div>

                        <div className="flex justify-between text-[12px]">
                            <div>Confidentiality:</div>
                            <div>Moderate</div>
                        </div>
                        <div className="flex justify-between text-[12px]">
                            <div>Integrity:</div>
                            <div>High</div>
                        </div>
                        <div className="flex justify-between text-[12px]">
                            <div>Availability:</div>
                            <div>Moderate</div>
                        </div>
                        <div className="flex justify-between text-[12px]">
                            <div>None:</div>
                            <div>High</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ControlSelection;
