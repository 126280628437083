import React from 'react';

const AvailableOverlays = () => {
  return (
    <div className="p-4">
      {/* Heading */}
      <h2 className="text-sm font-semibold mb-4">Available Overlays (Applied: 1)</h2>

      {/* Table */}
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-200 rounded-lg">
          <thead>
            <tr className="bg-gray-100 text-xs  text-gray-600 uppercase tracking-wider">
              <th className="px-6 py-3 text-center">Status</th>
              <th className="px-6 py-3 text-center">Overlay Name</th>
              <th className="px-6 py-3 text-center">Description</th>
            </tr>
          </thead>
          <tbody className="text-xs text-gray-700">
            <tr className="border-b border-gray-200">
              <td className="px-6 py-4">Applied</td>
              <td className="px-6 py-4">
                <span className="inline-block bg-blue-500 text-white px-2 py-1 rounded">Privacy</span>
              </td>
              <td className="px-6 py-4">Does the information system contain PII?</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AvailableOverlays;
