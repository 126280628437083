import React from 'react';

export default function EditRoles() {
  const roles = [
    'Authorizing Official',
    'AO Representative',
    'Component Chief Information Officer (CIO)',
    'Security Controls Assessor (SCA)',
    'Information Owner (IO)',
    'Incident Response POCs',
    'IS Security Engineer',
    'Information System Owner (ISO)',
    'Information System Security Manager (ISSM)',
    'Information System Security Officer (ISSO)',
    'Program Manager/System Manager',
  ];

  return (
    <div className=" ml-[240px] p-5 ">
      <h2 className="text-2xl text-start font-semibold mb-6">Edit Roles</h2>
      
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white">
          <thead className="bg-gray-100 border-b">
            <tr>
              <th className="text-left px-4 py-2 text-gray-600 font-medium">Role</th>
              <th className="text-left px-4 py-2 text-gray-600 font-medium">Full Name (Including Rank)</th>
              <th className="text-left px-4 py-2 text-gray-600 font-medium">Email</th>
              <th className="text-left px-4 py-2 text-gray-600 font-medium">Phone Number</th>
            </tr>
          </thead>
          <tbody>
            {roles.map((role, index) => (
              <tr key={index} className="border-b">
                <td className="px-4 py-2 text-gray-700 text-start " style={{fontSize:'12px'}}>{role}:</td>
                <td className="px-4 py-2">
                  <input 
                    type="text" 
                    className="w-full border border-gray-300 p-2 rounded bg-gray-50 text-gray-800 focus:ring-2 focus:ring-gray-800 focus:border-gray-800" 
                    placeholder="Enter full name"
                  />
                </td>
                <td className="px-4 py-2">
                  <input 
                    type="email" 
                    className="w-full border border-gray-300 p-2 rounded bg-gray-50 text-gray-800 focus:ring-2 focus:ring-gray-800 focus:border-gray-800" 
                    placeholder="Enter email"
                  />
                </td>
                <td className="px-4 py-2">
                  <input 
                    type="tel" 
                    className="w-full border border-gray-300 p-2 rounded bg-gray-50 text-gray-800 focus:ring-2 focus:ring-gray-800 focus:border-gray-800" 
                    placeholder="Enter phone number"
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className='flex justify-between my-16'>
          <button className='w-32 py-2  bg-white border-2 text-gray-800 rounded-full '>Cancel</button>
          <button className='w-32 py-2  bg-gray-800 text-white rounded-full '>Save</button>
        </div>
      </div>
    </div>
  );
}
