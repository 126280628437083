import React, { useState } from 'react';

const SystemsTable = () => {
    const [selectedItems, setSelectedItems] = useState([]);
    const systems =  [
        { id: 1, acronym: 'TEST1', name: 'Secure Information Technology Project', status: 'Authorization to Operate (ATO)' },
        { id: 2, acronym: 'TEST2', name: 'Test Project', status: 'Not Yet Authorized' },
        { id: 3, acronym: 'TEST3', name: 'Test Project', status: 'Authorization to Operate (ATO)' },
    ];
    const handleSelectItem = (id) => {
        setSelectedItems((prevSelected) =>
            prevSelected.includes(id)
                ? prevSelected.filter((item) => item !== id)
                : [...prevSelected, id]
        );
    };

    const handleDeleteSelected = () => {
        // Handle deletion of selected items here
        console.log('Deleting selected items:', selectedItems);
        // Clear selected items after deletion
        setSelectedItems([]);
    };

    return (
        <div className="md:ml-[240px] p-5">
            <div className="flex justify-between items-center mb-4">
                <h2 className="text-lg font-semibold">Search Systems</h2>
                <button
                    className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
                    onClick={handleDeleteSelected}
                    disabled={selectedItems.length === 0}
                >
                    Delete Selected
                </button>
            </div>
            <div className="overflow-x-auto">
                <table className="min-w-full bg-white border border-gray-300 ">
                    <thead >
                        <tr>
                            <th className="px-4 py-2 border-b-2 border-gray-200 bg-gray-800 text-white text-[14px]  text-center">Acronym</th>
                            <th className="px-4 py-2 border-b-2 border-gray-200 bg-gray-800 text-white text-[14px]  text-center">System Project Name</th>
                            <th className="px-4 py-2 border-b-2 border-gray-200 bg-gray-800 text-white text-[14px]  text-center">Authorization Status</th>
                            <th className="px-4 py-2 border-b-2 border-gray-200 bg-gray-800 text-white text-[14px]  text-center">Select</th>
                        </tr>
                    </thead>
                    <tbody className='bg-gray-100'>
                        {systems.map((system) => (
                            <tr key={system.id} className="border-b hover:bg-gray-100">
                                <td className="px-4 text-[12px] py-2">{system.acronym}</td>
                                <td className="px-4 text-[12px] py-2">{system.name}</td>
                                <td className="px-4 text-[12px] py-2">{system.status}</td>
                                <td className="px-4 text-[12px] py-2 text-center">
                                    <input
                                        type="checkbox"
                                        className="form-checkbox h-4 w-4 text-blue-600"
                                        checked={selectedItems.includes(system.id)}
                                        onChange={() => handleSelectItem(system.id)}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="mt-4 text-right">
                <span className="text-sm text-gray-500">
                    Showing {systems.length} entries
                </span>
            </div>
        </div>
    );
};

export default SystemsTable;
