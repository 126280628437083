import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { NavLink, useLocation } from 'react-router-dom';
import { Button } from '@mui/material';

function MyAppBarLinks() {
  const location = useLocation();
  const [value, setValue] = React.useState(0);

  React.useEffect(() => {
    // Set the active tab based on the current location
    switch (location.pathname) {
      case '/home/roles':
        setValue(1);
        break;
      case '/home/edit-system':
        setValue(2);
        break;
      case '/home/review':
        setValue(3);
        break;
      case '/home/auth-info':
        setValue(4);
        break;
      case '/home':
        setValue(0);
        break;
      default:
        setValue(0);
        break;
    }
  }, [location.pathname]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        display: { xs: 'none', md: 'flex' },
        gap: 2,
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="nav tabs"
        indicatorColor="primary" // Use primary color for the underline
        textColor="white" // Use primary color for the text
        sx={{
          '& .MuiTabs-indicator': {
            backgroundColor: 'white', // Customize the underline color to black
          },
        }}
      >
        <Tab
          label="Home"
          component={NavLink}
          to="/home/"
          sx={{
            textDecoration: 'none',
            color: 'white',
            fontWeight: 'bold' ,
            
            '&.Mui-selected': {
              color: 'white', // Set the text color of the selected tab
            },
          }}
          />
        <Tab
          label="Authorization"
          component={NavLink}
          to="/home/roles"
          sx={{
            textDecoration: 'none',
            color: 'white',
            fontWeight: 'bold' ,
            
            '&.Mui-selected': {
              color: 'white', // Set the text color of the selected tab
            },
          }}
          />
        <Tab
          label="Reports"
          component={NavLink}
          to="/home/edit-system"
          sx={{
            textDecoration: 'none',
            color: 'white',
            fontWeight: 'bold' ,
            
            '&.Mui-selected': {
              color: 'white', // Set the text color of the selected tab
            },
          }}
          />
        <Tab
          label="CAM"
          component={NavLink}
          to="/home/review"
          sx={{
            textDecoration: 'none',
            color: 'white',
            fontWeight: 'bold' ,
            
            '&.Mui-selected': {
              color: 'white', // Set the text color of the selected tab
            },
          }}
          />
        <Tab
          label="Administration"
          component={NavLink}
          to="/home/auth-info"
          sx={{
            textDecoration: 'none',
            color: 'white',
            fontWeight: 'bold' ,

            '&.Mui-selected': {
              color: 'white', // Set the text color of the selected tab
            },
          }}
        />
      </Tabs>
      <NavLink to="/" style={{ textDecoration: 'none' }}>
      <Button
          variant="contained"
          color="primary"
          sx={{
            width: '6rem', // Equivalent to w-32
            paddingY: '0.5rem', // Equivalent to py-2
            backgroundColor: '#FFFFFF', // Equivalent to bg-gray-800
            color: '#1F2937', // Equivalent to text-white
            borderRadius: '9999px', // Equivalent to rounded-full
            textTransform: 'none', // To prevent the button text from being uppercase
            // boxShadow: 'none', // Optional: Removes the default shadow
            backgroundColor: '#fff', // Maintain the background color on hover
            '&:hover': {
            }
          }}
        >
          Help
        </Button>

      </NavLink>
      <NavLink to="/" style={{ textDecoration: 'none' }}>
        <Button
          variant="contained"
          color="primary"
          sx={{
            width: '6rem', // Equivalent to w-32
            paddingY: '0.5rem', // Equivalent to py-2
            backgroundColor: '#FFFFFF', // Equivalent to bg-gray-800
            color: '#1F2937', // Equivalent to text-white
            borderRadius: '9999px', // Equivalent to rounded-full
            textTransform: 'none', // To prevent the button text from being uppercase
            // boxShadow: 'none', // Optional: Removes the default shadow
            backgroundColor: '#fff', // Maintain the background color on hover
            '&:hover': {
            }
          }}
        >
          Admin
        </Button>

      </NavLink>
    </Box>
  );
}

export default MyAppBarLinks;
