import React from 'react';

const SystemDetails = () => {
    return (
        <div className="ml-[240px] p-8  min-h-screen">
             <div className="flex justify-start mb-4">
                <h2 className="text-lg font-semibold">System Details</h2>
                
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-4 gap-8">
                {/* Sidebar Navigation */}
                <div className="bg-white shadow-md h-fit rounded-lg  lg:col-span-1">
                    <h2 className="text-[14px] font-semibold text-gray-700  bg-gray-800 py-4 rounded-t-lg  text-white  ">System Details Options</h2>
                    <ul className="space-y-4  bg-gray-100 py-4">
                        <li>
                            <a href="#" className="text-[12px] text-gray-700 hover:text-blue-600">System Information</a>
                        </li>
                        <li>
                            <a href="#" className="text-[12px] text-gray-700 hover:text-blue-600">Authorization Information</a>
                        </li>
                        <li>
                            <a href="#" className="text-[12px] text-gray-700 hover:text-blue-600">FISMA</a>
                        </li>
                        <li>
                            <a href="#" className="text-[12px] text-gray-700 hover:text-blue-600">Business</a>
                        </li>
                        <li>
                            <a href="#" className="text-[12px] text-gray-700 hover:text-blue-600">External Security Services</a>
                        </li>
                        <li>
                            <a href="#" className="text-[12px] text-gray-700 hover:text-blue-600">Rules</a>
                        </li>
                    </ul>
                </div>

                {/* System Information Content */}
                <div className="bg-white shadow-md  lg:col-span-3">
                    <h2 className="text-[14px] font-semibold text-white bg-gray-800 py-4 rounded-t-lg  text-white  ">System Information</h2>
                    <table className="min-w-full bg-gray-100  border border-gray-300 ">
                        <tbody className="divide-y divide-gray-300 rounded-b-lg">
                            <tr>
                                <td className="p-4 text-[12px] text-gray-600">Registration Type</td>
                                <td className="p-4 text-[12px] text-gray-800">Access Only</td>
                            </tr>
                            <tr>
                                <td className="p-4 text-[12px] text-gray-600">System Identification</td>
                                <td className="p-4 text-[12px] text-gray-800">105</td>
                            </tr>
                            <tr>
                                <td className="p-4 text-[12px] text-gray-600">System Name</td>
                                <td className="p-4 text-[12px] text-gray-800">Reserve Information Technology Operations Facility</td>
                            </tr>
                            <tr>
                                <td className="p-4 text-[12px] text-gray-600">System Acronym</td>
                                <td className="p-4 text-[12px] text-gray-800">ITOF</td>
                            </tr>
                            <tr>
                                <td className="p-4 text-[12px] text-gray-600">Information System Owner</td>
                                <td className="p-4 text-[12px] text-gray-800">U.S. Army</td>
                            </tr>
                            <tr>
                                <td className="p-4 text-[12px] text-gray-600">Version / Release Number</td>
                                <td className="p-4 text-[12px] text-gray-800">v2.3</td>
                            </tr>
                            <tr>
                                <td className="p-4 text-[12px] text-gray-600">System Type</td>
                                <td className="p-4 text-[12px] text-gray-800">IT Enclave</td>
                            </tr>
                            <tr>
                                <td className="p-4 text-[12px] text-gray-600">System Life Cycle / Acquisition Phase</td>
                                <td className="p-4 text-[12px] text-gray-800">Full Rate Production/Deployment (Operations & Sustainment)</td>
                            </tr>
                            <tr>
                                <td className="p-4 text-[12px] text-gray-600">National Security System</td>
                                <td className="p-4 text-[12px] text-gray-800">No</td>
                            </tr>
                            <tr>
                                <td className="p-4 text-[12px] text-gray-600">Financial Management System</td>
                                <td className="p-4 text-[12px] text-gray-800">No</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default SystemDetails;
