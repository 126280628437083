import * as React from 'react';
import { Breadcrumbs, Link, Typography } from '@mui/material';
import { NavLink } from 'react-router-dom';

export default function DynamicBreadcrumbs({ breadcrumbs }) {
    return (
        <div className='md:ml-[240px] px-8 pt-5'>
            <Breadcrumbs aria-label="breadcrumb">

                {breadcrumbs.map((breadcrumb, index) => {
                    const { label, path } = breadcrumb;
                    const isLast = index === breadcrumbs.length - 1;

                    return isLast ? (
                        <Typography color="#1f2937" sx={{ fontWeight: 'bold' }} key={path}>
                            {label}
                        </Typography>
                    ) : (
                        <Link underline="hover" color="#1f2937" sx={{ fontWeight: 'bold' }} component={NavLink} to={path} key={path}>
                            {label}
                        </Link>
                    );
                })}
            </Breadcrumbs>
        </div>
    );
}
