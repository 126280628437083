import React from 'react';

const PrimarySecurityControlSet = () => {
  return (
    <div className="p-8 ml-[240px]">
      {/* <h2 className="text-lg font-semibold mb-4">Primary Security Control Set</h2> */}
      <div className="flex justify-start mb-8">
                <h2 className="text-lg font-semibold">Primary Security Control Set</h2>
            </div>
      <div className="space-y-4">
        {/* Primary Security Control Set Selection */}
        <div className="flex justify-between items-center text-[12px]">
          <label className="text-sm font-semibold" htmlFor="control-set">
            Primary Security Control Set:
          </label>
          <select
            id="control-set"
            className="w-1/2 px-3 py-2 border border-gray-300 rounded"
          >
            <option>NIST SP 800-53 Revision 4</option>
            {/* Add more options here */}
          </select>
        </div>

        {/* Confidentiality */}
        <div className="flex justify-between items-center text-[12px]">
          <label className="text-sm font-semibold" htmlFor="confidentiality">
            Confidentiality:
          </label>
          <select
            id="confidentiality"
            className="w-1/2 px-3 py-2 border border-gray-300 rounded"
          >
            <option>Moderate</option>
            <option>High</option>
            <option>Low</option>
            {/* Add more options here */}
          </select>
        </div>

        {/* Integrity */}
        <div className="flex justify-between items-center text-[12px]">
          <label className="text-sm font-semibold" htmlFor="integrity">
            Integrity:
          </label>
          <select
            id="integrity"
            className="w-1/2 px-3 py-2 border border-gray-300 rounded"
          >
            <option>High</option>
            <option>Moderate</option>
            <option>Low</option>
            {/* Add more options here */}
          </select>
        </div>

        {/* Availability */}
        <div className="flex justify-between items-center text-[12px]">
          <label className="text-sm font-semibold" htmlFor="availability">
            Availability:
          </label>
          <select
            id="availability"
            className="w-1/2 px-3 py-2 border border-gray-300 rounded"
          >
            <option>Moderate</option>
            <option>High</option>
            <option>Low</option>
            {/* Add more options here */}
          </select>
        </div>

        {/* Impact */}
        <div className="flex justify-between items-center text-[12px]">
          <label className="text-sm font-semibold" htmlFor="impact">
            Impact:
          </label>
          <select
            id="impact"
            className="w-1/2 px-3 py-2 border border-gray-300 rounded"
          >
            <option>High</option>
            <option>Moderate</option>
            <option>Low</option>
            {/* Add more options here */}
          </select>
        </div>

        {/* Information Type Selection */}
        <div className="flex justify-between items-center text-[12px]">
          <label className="text-sm font-semibold" htmlFor="info-type">
            Information Type Selection:
          </label>
          <input
            type="text"
            id="info-type"
            className="w-1/2 px-3 py-2 border border-gray-300 rounded"
          />
        </div>
        <div className="flex justify-end">
          <button className="text-sm text-white bg-blue-500 px-4 py-2 rounded">
            Browse
          </button>
        </div>
      </div>
    </div>
  );
};

export default PrimarySecurityControlSet;
