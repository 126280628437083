import React from "react";
import logo from '../assets/1.png'
import {Link} from 'react-router-dom';

const GRCPlatformSimulator = () => {
    return (
        <div className="min-h-screen bg-white-100 flex flex-col items-center ">
            {/* Header */}
            <header className="bg-gray-800 w-full py-4 ">
                <h1 className="text-center text-white text-xl md:text-2xl font-bold p-2">
                    RiskForce - GRC Platform Simulator
                </h1>
            </header>
            <div className=" max-w-4xl mt-5 px-4 md:px-0">

                {/* Main Content */}
                <h2 className="text-lg font-semibold">RiskForce GRC Platform Simulator Site Agreement</h2>
                <p className="mt-4 text-sm">
                    You are accessing a RiskForce Information System (IS) that is provided for the RiskForce Authorized use only. By using this IS (which includes any device attached to this IS), you consent to the following conditions:
                </p>

                <main className="flex flex-col md:flex-row justify-between items-center mt-8 p-4 w-full  bg-white ">
                    {/* Text Section */}
                    <div className="w-full">
                        <ul className="list-disc pl-5 mt-2 text-sm text-justify">
                            <li>RiskForce routinely intercepts and monitors communications on this IS for purposes including, but not limited to, penetration testing, COMSEC monitoring, network operations and defense, personnel misconduct (PM), law enforcement (LE), and counterintelligence (CI) investigations.</li>
                            <li>At any time, RiskForce may inspect and seize data stored on this IS.</li>
                            <li>Communications using, or data stored on this IS are not private, are subject to routine monitoring, interception, and search, and may be disclosed or used for any RiskForce-authorized purpose.</li>
                            <li>This IS includes security measures (e.g., authentication and access controls) to protect RiskForce interests--not for your personal benefit or privacy.</li>
                            <li>Notwithstanding the above, using this IS does not constitute consent to PM, LE, or CI investigative searching or monitoring of the content of privileged communications, or work product, related to personal representation or services by attorneys, psychotherapists, or clergy, and their assistants. Such communications and work product are private and confidential. See User Agreement for details.</li>
                        </ul>
                    </div>

                </main>

                <Link to={'/home'}>
                <button className="mt-4 bg-gray-800 text-white py-2 px-4 rounded hover:bg-gray-700">
                    Begin The Simulation
                </button>
                </Link>
            </div>

            {/* Footer */}
            <footer className="mt-8 p-4 text-center text-sm text-gray-600 bg-gray-100">
                <p>Copyright &copy; RiskForce - All rights reserved</p>
            </footer>
        </div>
    );
};

export default GRCPlatformSimulator;
