import React from 'react';

const ManageSecurityControls = () => {
  return (
    <div className="p-4 ">
      {/* Heading */}
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-lg font-semibold">Manage Security Controls</h2>
        <div>
          <button className="text-sm text-blue-500 border border-blue-500 px-4 py-2 rounded mr-2">Add Additional Controls</button>
          <button className="text-sm text-red-500 border border-red-500 px-4 py-2 rounded">Delete Selected</button>
        </div>
      </div>

      {/* Table */}
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-200 rounded-lg">
          <thead>
            <tr className="bg-gray-100 text-xs text-gray-600 uppercase tracking-wider">
              <th className="px-6 py-3 text-left">Type</th>
              <th className="px-6 py-3 text-left">Acronym</th>
              <th className="px-6 py-3 text-left">Name</th>
              <th className="px-6 py-3 text-left">Control Set</th>
              <th className="px-6 py-3 text-left">Control Family</th>
              <th className="px-6 py-3 text-left">Comments</th>
              <th className="px-6 py-3 text-left">Select</th>
            </tr>
          </thead>
          <tbody className="text-xs text-gray-700">
            <tr className="border-b border-gray-200">
              <td className="px-6 py-4">Baseline</td>
              <td className="px-6 py-4">AC-1</td>
              <td className="px-6 py-4">ACCESS CONTROL POLICY AND PROCEDURES</td>
              <td className="px-6 py-4">NIST SP 800-53 Revision 4</td>
              <td className="px-6 py-4">ACCESS CONTROL</td>
              <td className="px-6 py-4">Comments</td>
              <td className="px-6 py-4">
                <input type="checkbox" className="form-checkbox h-4 w-4 text-blue-600" />
              </td>
            </tr>
            {/* Add more rows as needed */}
            <tr className="border-b border-gray-200">
              <td className="px-6 py-4">Baseline</td>
              <td className="px-6 py-4">AT-1</td>
              <td className="px-6 py-4">SECURITY AWARENESS AND TRAINING POLICY AND PROCEDURES</td>
              <td className="px-6 py-4">NIST SP 800-53 Revision 4</td>
              <td className="px-6 py-4">AWARENESS AND TRAINING</td>
              <td className="px-6 py-4">-</td>
              <td className="px-6 py-4">
                <input type="checkbox" className="form-checkbox h-4 w-4 text-blue-600" />
              </td>
            </tr>
            {/* Additional rows can be added similarly */}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ManageSecurityControls;
