import React, { createContext, useState, useContext } from 'react';

// Create the AuthContext
const AuthContext = createContext();

// Provide the AuthContext to components
export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  // Hardcoded username and password (for demonstration purposes)
  const validUsername = "testaccount";
  const validPassword = "57o42Zneqkdc";

  const login = (username, password) => {
    console.log("Attempting login with username:", username); // Debugging log

    if (username === validUsername && password === validPassword) {
      setIsAuthenticated(true);
      console.log("Login successful, isAuthenticated:", isAuthenticated); // Debugging log
      return true; // Indicate successful login
    } else {
      alert("Invalid username or password");
      console.log("Login failed, isAuthenticated:", isAuthenticated); // Debugging log
      return false; // Indicate failed login
    }
  };

  const logout = () => {
    setIsAuthenticated(false);
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook to use the AuthContext
export const useAuth = () => {
  return useContext(AuthContext);
};
