import React from 'react';

const InformationTypes = () => {
  return (
    <div className="p-4 ml-[240px]">
      <h2 className="text-lg font-semibold mb-4">Information Types</h2>

      {/* Information Types Listing */}
      <div className="mb-8">
        <h3 className="text-sm font-semibold mb-2">Information Types Listing</h3>
        <div className="mb-4">
          <select className="w-full px-3 py-2 border border-gray-300 rounded">
            <option>Select one or many</option>
            {/* Add more options here */}
          </select>
        </div>
        <div className="mb-4">
          <input
            type="text"
            placeholder="Enter at least 2 characters"
            className="w-full px-3 py-2 border border-gray-300 rounded"
          />
          <p className="text-xs text-gray-500 mt-1">
            Information Type Name (e.g. Central Fiscal Operations, etc.)
          </p>
        </div>
        <button className="w-full text-sm text-white bg-blue-500 px-4 py-2 rounded">Search</button>
        <div className="mt-4 flex justify-between items-center">
          <h4 className="text-sm font-semibold mb-2">Search Results</h4>
          <button className="text-sm text-blue-500 border border-blue-500 px-4 py-2 rounded w-40">Add Value</button>
        </div>
      </div>

      {/* Recommended Categorization */}
      <div className="mb-8">
        <h3 className="text-sm font-semibold mb-2">Recommended Categorization</h3>
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white border border-gray-200 rounded-lg">
            <thead>
              <tr className="bg-gray-100 text-xs text-gray-600 uppercase tracking-wider">
                <th className="px-6 py-3 text-center">Confidentiality</th>
                <th className="px-6 py-3 text-center">Integrity</th>
                <th className="px-6 py-3 text-center">Availability</th>
              </tr>
            </thead>
            <tbody className="text-xs text-gray-700">
              <tr className="border-b border-gray-200">
                <td className="px-6 py-4">Moderate</td>
                <td className="px-6 py-4">High</td>
                <td className="px-6 py-4">Moderate</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      {/* Selected Information Types */}
      <div className="mb-8">
        <h3 className="text-sm font-semibold mb-2">Selected Information Types(2)</h3>
        <div className="space-y-4">
          <div className="flex flex-col space-y-2">
            <span className="text-sm">Tax and Fiscal Policy</span>
            <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
              <select className="w-full px-3 py-2 border border-gray-300 rounded">
                <option>Confidentiality</option>
                <option>Moderate</option>
                {/* Add more options here */}
              </select>
              <select className="w-full px-3 py-2 border border-gray-300 rounded">
                <option>Integrity</option>
                <option>High</option>
                {/* Add more options here */}
              </select>
              <select className="w-full px-3 py-2 border border-gray-300 rounded">
                <option>Availability</option>
                <option>Moderate</option>
                {/* Add more options here */}
              </select>
            </div>
            <button className="text-red-500 border border-red-500 px-2 py-1 rounded self-start">Remove</button>
          </div>
          <div className="flex flex-col space-y-2">
            <span className="text-sm">Central Fiscal Operations</span>
            <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
              <select className="w-full px-3 py-2 border border-gray-300 rounded">
                <option>Confidentiality</option>
                <option>Moderate</option>
                {/* Add more options here */}
              </select>
              <select className="w-full px-3 py-2 border border-gray-300 rounded">
                <option>Integrity</option>
                <option>Low</option>
                {/* Add more options here */}
              </select>
              <select className="w-full px-3 py-2 border border-gray-300 rounded">
                <option>Availability</option>
                <option>Low</option>
                {/* Add more options here */}
              </select>
            </div>
            <button className="text-red-500 border border-red-500 px-2 py-1 rounded self-start">Remove</button>
          </div>
        </div>
      </div>

      {/* Save and Back Buttons */}
      <div className="flex justify-end space-x-4">
        <button className="text-sm text-blue-500 border border-blue-500 px-4 py-2 rounded">Save</button>
        <button className="text-sm text-gray-500 border border-gray-300 px-4 py-2 rounded">Back</button>
      </div>
    </div>
  );
};

export default InformationTypes;
