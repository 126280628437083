import React from 'react';

const Dashboard = () => {
    return (
        <div className="md:ml-[240px] p-8 min-h-screen">
            <div className="flex justify-start mb-4">
                <h2 className="text-lg font-semibold">Dashboard</h2>
            </div>

            <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
                {/* Control Summary Section */}
                <div className=" rounded-lg h-fit  mb-5">
                    <div className="mb-6 ">
                        <h2 className="text-[14px] font-semibold text-white bg-gray-800 p-2 rounded-t-lg">Control Summary</h2>
                        <table className="min-w-full bg-gray-100 border border-gray-300">
                            <tbody className="divide-y divide-gray-300">
                                <tr>
                                    <td className="p-4 text-[12px] text-gray-600">Primary Security Control Set</td>
                                    <td className="p-4 text-[12px] text-gray-800">AB-7 SR-02 & Review A</td>
                                </tr>
                                <tr>
                                    <td className="p-4 text-[12px] text-gray-600">Number of Security Controls</td>
                                    <td className="p-4 text-[12px] text-gray-800">37</td>
                                </tr>
                                <tr>
                                    <td className="p-4 text-[12px] text-gray-600">Compliant</td>
                                    <td className="p-4 text-[12px] text-gray-800">29</td>
                                </tr>
                                <tr>
                                    <td className="p-4 text-[12px] text-gray-600">Non-Compliant</td>
                                    <td className="p-4 text-[12px] text-gray-800">8</td>
                                </tr>
                                <tr>
                                    <td className="p-4 text-[12px] text-gray-600">Not Applicable</td>
                                    <td className="p-4 text-[12px] text-gray-800">0</td>
                                </tr>
                                <tr>
                                    <td className="p-4 text-[12px] text-gray-600">Number of Assessment Procedures</td>
                                    <td className="p-4 text-[12px] text-gray-800">1053</td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="mt-2 text-right mx-6">
                            <a href="#" className="text-blue-600 hover:text-blue-800 text-[12px] font-medium">View Controls</a>
                        </div>
                    </div>
                    <div className="mb-6 ">
                        <h2 className="text-[14px] font-semibold text-white bg-gray-800 p-2 rounded-t-lg">Enterprise Reporting</h2>
                        <table className="min-w-full bg-gray-100 border border-gray-300">
                            <tbody className="divide-y divide-gray-300">
                              
                                
                            </tbody>
                        </table>
                        <div className="mt-2 text-right mx-6">
                            <a href="#" className="text-blue-600 hover:text-blue-800 text-[12px] font-medium">View Controls</a>
                        </div>
                    </div>
                </div>

                {/* System Information Summary Section */}
                <div className=" shadow-md rounded-lg bg-gray-100">
                    <h2 className="text-[14px] font-semibold text-white bg-gray-800 p-2 rounded-t-lg">System Information Summary</h2>
                    <table className="min-w-full bg-gray-100 border border-gray-300">
                        <tbody className="divide-y divide-gray-300">
                            <tr>
                                <td className="p-4 text-[12px] text-gray-600">System Identification</td>
                                <td className="p-4 text-[12px] text-gray-800">105</td>
                            </tr>
                            <tr>
                                <td className="p-4 text-[12px] text-gray-600">Risk Management Type</td>
                                <td className="p-4 text-[12px] text-gray-800">Access Only</td>
                            </tr>
                            <tr>
                                <td className="p-4 text-[12px] text-gray-600">System Acronym</td>
                                <td className="p-4 text-[12px] text-gray-800">ITOP</td>
                            </tr>
                            <tr>
                                <td className="p-4 text-[12px] text-gray-600">System Name</td>
                                <td className="p-4 text-[12px] text-gray-800">Process Information Technology Operation Facility</td>
                            </tr>
                            <tr>
                                <td className="p-4 text-[12px] text-gray-600">System Version Number</td>
                                <td className="p-4 text-[12px] text-gray-800">v2.3</td>
                            </tr>
                            <tr>
                                <td className="p-4 text-[12px] text-gray-600">Information Owner</td>
                                <td className="p-4 text-[12px] text-gray-800">U.S. Army</td>
                            </tr>
                            <tr>
                                <td className="p-4 text-[12px] text-gray-600">System Authorization Status</td>
                                <td className="p-4 text-[12px] text-gray-800">Authorization to Operate (ATO)</td>
                            </tr>
                            <tr>
                                <td className="p-4 text-[12px] text-gray-600">Authorization Termination Date</td>
                                <td className="p-4 text-[12px] text-gray-800">03/12/2026</td>
                            </tr>
                            <tr>
                                <td className="p-4 text-[12px] text-gray-600">Security Plan Approval Status</td>
                                <td className="p-4 text-[12px] text-gray-800">Not Yet Approved</td>
                            </tr>
                            <tr>
                                <td className="p-4 text-[12px] text-gray-600">Security Plan Approval Date</td>
                                <td className="p-4 text-[12px] text-gray-800">N/A</td>
                            </tr>
                            <tr>
                                <td className="p-4 text-[12px] text-gray-600">System Security Plan</td>
                                <td className="p-4 text-[12px] text-gray-800">LS Standard</td>
                            </tr>
                            <tr>
                                <td className="p-4 text-[12px] text-gray-600">National Security System</td>
                                <td className="p-4 text-[12px] text-gray-800">0</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>


        </div>
    );
};

export default Dashboard;
