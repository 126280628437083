import React, { useState } from 'react';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { NavLink } from 'react-router-dom';
import mylogo from '../assets/logo.png';
import TextField from '@mui/material/TextField';

const drawerWidth = 240;

const Sidebar3 = ({ mobileOpen, handleDrawerToggle, isMobile }) => {
    const [openSections, setOpenSections] = useState({
        system: true,
        controls: true,
        assets: true,
        poam: true,
        artifacts: true,
        reports: true,
        workflow: true,
        relationships: true,
        management: true
    });

    const handleToggle = (section) => {
        setOpenSections((prevState) => ({
            ...prevState,
            [section]: !prevState[section],
        }));
    };

    const drawer = (
        <div>
            <img src={mylogo} alt="logo" className='w-[200px] mt-0 mx-auto' />
            <Toolbar sx={{ marginBottom: -5, paddingBottom: 0 }} />

            {/* Search Bar */}
            <Box sx={{ px: 2, pb: 2 }}>
                <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Search"
                    size="small"
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            borderRadius: '20px',
                            fontSize: '12px',
                        },
                        '& .MuiInputBase-input': {
                            padding: '8px 10px',
                        },
                    }}
                />
            </Box>

            <Box sx={{ p: 2 }}>
                <Typography variant="h5" gutterBottom sx={{ textAlign: 'left', pl: 0, ml: 0 }}>
                    Authorization
                </Typography>
                
                <List>
                    {/* System Section */}
                    <ListItemButton onClick={() => handleToggle('system')}>
                        <ListItemText primary="System" />
                        {openSections.system ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={openSections.system} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {[
                                { text: 'Dashboard', path: '/search/dashboard' },
                                { text: 'Details', path: '/search/system-details' },
                                { text: 'Categorization', path: '/search/categorization' },
                            ].map(({ text, path }) => (
                                <ListItem key={text} disablePadding>
                                    <ListItemButton component={NavLink} to={path} sx={{ pl: 4, borderRadius: 1 }}
                                        activeClassName="Mui-selected"
                                        exact
                                        style={({ isActive }) => ({
                                            background: isActive ? '#1f2937' : 'inherit',
                                            color: isActive ? '#fff' : 'inherit',
                                        })}
                                    >
                                        <ListItemText primary={text} sx={{ '& .MuiTypography-root': { fontSize: '12px' } }} />
                                    </ListItemButton>
                                </ListItem>
                            ))}
                        </List>
                    </Collapse>

                    {/* Controls Section */}
                    <ListItemButton onClick={() => handleToggle('controls')}>
                        <ListItemText primary="Controls" />
                        {openSections.controls ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={openSections.controls} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {[
                                { text: 'Controls Listing', path: '/search/control-listing' },
                                { text: 'Implementation Plan', path: '/home/implementation-plan' },
                                { text: 'Risk Assessment', path: '/home/risk-assessment' },
                                { text: 'Import/Export', path: '/home/import-export' },
                                { text: 'Bulk Processing', path: '/home/bulk-processing' },
                            ].map(({ text, path }) => (
                                <ListItem key={text} disablePadding>
                                    <ListItemButton component={NavLink} to={path} sx={{ pl: 4, borderRadius: 1 }}
                                        activeClassName="Mui-selected"
                                        exact
                                        style={({ isActive }) => ({
                                            background: isActive ? '#1f2937' : 'inherit',
                                    color: isActive ? '#fff' : 'inherit',
                                        })}
                                    >
                                        <ListItemText primary={text} sx={{ '& .MuiTypography-root': { fontSize: '12px' } }} />
                                    </ListItemButton>
                                </ListItem>
                            ))}
                        </List>
                    </Collapse>

                    {/* Assets Section */}
                    <ListItemButton onClick={() => handleToggle('assets')}>
                        <ListItemText primary="Assets" />
                        {openSections.assets ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={openSections.assets} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {[
                                { text: 'Actions', path: '/home/actions' },
                                { text: 'Findings', path: '/home/findings' },
                                { text: 'Resources', path: '/home/resources' },
                                { text: 'HW Baseline', path: '/home/hw-baseline' },
                                { text: 'SW Baseline', path: '/home/sw-baseline' },
                                { text: 'Ports/Protocols', path: '/home/ports-protocols' },
                                { text: 'Import/Export', path: '/home/import-export-assets' },
                            ].map(({ text, path }) => (
                                <ListItem key={text} disablePadding>
                                    <ListItemButton component={NavLink} to={path} sx={{ pl: 4, borderRadius: 1 }}
                                        activeClassName="Mui-selected"
                                        exact
                                        style={({ isActive }) => ({
                                            background: isActive ? '#1f2937' : 'inherit',
                                    color: isActive ? '#fff' : 'inherit',
                                        })}
                                    >
                                        <ListItemText primary={text} sx={{ '& .MuiTypography-root': { fontSize: '12px' } }} />
                                    </ListItemButton>
                                </ListItem>
                            ))}
                        </List>
                    </Collapse>

                    {/* POA&M Section */}
                    <ListItemButton onClick={() => handleToggle('poam')}>
                        <ListItemText primary="POA&M" />
                        {openSections.poam ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={openSections.poam} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {[
                                { text: 'POA&M Listing', path: '/home/poam-listing' },
                                { text: 'POA&M Import', path: '/home/poam-import' },
                                { text: 'POA&M Export', path: '/home/poam-export' },
                            ].map(({ text, path }) => (
                                <ListItem key={text} disablePadding>
                                    <ListItemButton component={NavLink} to={path} sx={{ pl: 4, borderRadius: 1 }}
                                        activeClassName="Mui-selected"
                                        exact
                                        style={({ isActive }) => ({
                                            background: isActive ? '#1f2937' : 'inherit',
                                            color: isActive ? '#fff' : 'inherit',
                                        })}
                                    >
                                        <ListItemText primary={text} sx={{ '& .MuiTypography-root': { fontSize: '12px' } }} />
                                    </ListItemButton>
                                </ListItem>
                            ))}
                        </List>
                    </Collapse>

                    {/* Artifacts Section */}
                    <ListItemButton onClick={() => handleToggle('artifacts')}>
                        <ListItemText primary="Artifacts" />
                        {openSections.artifacts ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={openSections.artifacts} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {[
                                { text: 'Artifacts Listing', path: '/home/artifacts-listing' },
                            ].map(({ text, path }) => (
                                <ListItem key={text} disablePadding>
                                    <ListItemButton component={NavLink} to={path} sx={{ pl: 4, borderRadius: 1 }}
                                        activeClassName="Mui-selected"
                                        exact
                                        style={({ isActive }) => ({
                                            background: isActive ? '#1f2937' : 'inherit',
                                            color: isActive ? '#fff' : 'inherit',
                                        })}
                                    >
                                        <ListItemText primary={text} sx={{ '& .MuiTypography-root': { fontSize: '12px' } }} />
                                    </ListItemButton>
                                </ListItem>
                            ))}
                        </List>
                    </Collapse>

                    {/* Reports Section */}
                    <ListItemButton onClick={() => handleToggle('reports')}>
                        <ListItemText primary="Reports" />
                        {openSections.reports ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={openSections.reports} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {[
                                { text: 'System Reports', path: '/home/system-reports' },
                            ].map(({ text, path }) => (
                                <ListItem key={text} disablePadding>
                                    <ListItemButton component={NavLink} to={path} sx={{ pl: 4, borderRadius: 1 }}
                                        activeClassName="Mui-selected"
                                        exact
                                        style={({ isActive }) => ({
                                            background: isActive ? '#1f2937' : 'inherit',
                                            color: isActive ? '#fff' : 'inherit',
                                        })}
                                    >
                                        <ListItemText primary={text} sx={{ '& .MuiTypography-root': { fontSize: '12px' } }} />
                                    </ListItemButton>
                                </ListItem>
                            ))}
                        </List>
                    </Collapse>

                    {/* Workflow Section */}
                    <ListItemButton onClick={() => handleToggle('workflow')}>
                        <ListItemText primary="Workflow" />
                        {openSections.workflow ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={openSections.workflow} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {[
                                { text: 'Active Workflow Listing', path: '/home/active-workflow-listing' },
                                { text: 'Historical Workflow Listing', path: '/home/historical-workflow-listing' },
                            ].map(({ text, path }) => (
                                <ListItem key={text} disablePadding>
                                    <ListItemButton component={NavLink} to={path} sx={{ pl: 4, borderRadius: 1 }}
                                        activeClassName="Mui-selected"
                                        exact
                                        style={({ isActive }) => ({
                                            background: isActive ? '#1f2937' : 'inherit',
                                            color: isActive ? '#fff' : 'inherit',
                                        })}
                                    >
                                        <ListItemText primary={text} sx={{ '& .MuiTypography-root': { fontSize: '12px' } }} />
                                    </ListItemButton>
                                </ListItem>
                            ))}
                        </List>
                    </Collapse>

                    {/* Relationships Section */}
                    <ListItemButton onClick={() => handleToggle('relationships')}>
                        <ListItemText primary="Relationships" />
                        {openSections.relationships ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={openSections.relationships} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {[
                                { text: 'Inheritance', path: '/home/inheritance' },
                                { text: 'Associations', path: '/home/associations' },
                                { text: 'Inheritability', path: '/home/inheritability' },
                                { text: 'Request & Approval', path: '/home/request-approval' },
                            ].map(({ text, path }) => (
                                <ListItem key={text} disablePadding>
                                    <ListItemButton component={NavLink} to={path} sx={{ pl: 4, borderRadius: 1 }}
                                        activeClassName="Mui-selected"
                                        exact
                                        style={({ isActive }) => ({
                                            background: isActive ? '#1f2937' : 'inherit',
                                            color: isActive ? '#fff' : 'inherit',
                                        })}
                                    >
                                        <ListItemText primary={text} sx={{ '& .MuiTypography-root': { fontSize: '12px' } }} />
                                    </ListItemButton>
                                </ListItem>
                            ))}
                        </List>
                    </Collapse>

                    {/* Management Section */}
                    <ListItemButton onClick={() => handleToggle('management')}>
                        <ListItemText primary="Management" />
                        {openSections.management ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={openSections.management} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {[
                                { text: 'Personnel', path: '/home/personnel' },
                                { text: 'Workload Tasks', path: '/home/workload-tasks' },
                                { text: 'Administration', path: '/home/administration' },
                            ].map(({ text, path }) => (
                                <ListItem key={text} disablePadding>
                                    <ListItemButton component={NavLink} to={path} sx={{ pl: 4, borderRadius: 1 }}
                                        activeClassName="Mui-selected"
                                        exact
                                        style={({ isActive }) => ({
                                            background: isActive ? '#1f2937' : 'inherit',
                                            color: isActive ? '#fff' : 'inherit',
                                        })}
                                    >
                                        <ListItemText primary={text} sx={{ '& .MuiTypography-root': { fontSize: '12px' } }} />
                                    </ListItemButton>
                                </ListItem>
                            ))}
                        </List>
                    </Collapse>

                </List>
            </Box>
        </div>
    );

    return (
        <Drawer
            variant={isMobile ? 'temporary' : 'permanent'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
                keepMounted: true,
            }}
            sx={{
                display: { xs: 'block', sm: 'block' },
                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
        >
            {drawer}
        </Drawer>
    );
};

export default Sidebar3;
