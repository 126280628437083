import React from 'react';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { NavLink } from 'react-router-dom';
import mylogo from '../assets/logo.png';

const drawerWidth = 240;

const Sidebar = ({ mobileOpen, handleDrawerToggle, isMobile }) => {
    const drawer = (
        <div>
            <img src={mylogo} alt="logo" className='w-[200px] mt-10 mx-auto' />
            <Toolbar />
          
            <Box sx={{ p: 2 }}>
                <Typography variant="h5" gutterBottom sx={{ textAlign: 'left', pl: 0, ml: 0 }}>
                    Authorization
                </Typography>
                <List>
                    {[
                        { text: 'System Information', path: '/home/edit-system' },
                        { text: 'Authorization Information', path: '/home/auth-info' },
                        { text: 'Roles', path: '/home/roles' },
                        { text: 'Review & Submit', path: '/home/review' }
                    ].map(({ text, path }) => (
                        <ListItem key={text} disablePadding>
                            <ListItemButton component={NavLink} to={path} sx={{ pl:1,borderRadius:1}}
                                activeClassName="Mui-selected"
                                exact
                                style={({ isActive }) => ({
                                 
                                    background: isActive ? '#1f2937' : 'inherit',
                                    color: isActive ? '#fff' : 'inherit',
                                })}
                            >
                                <ListItemIcon sx={{ minWidth: '0px' }}>
                                    {/* You can add an icon here if needed */}
                                </ListItemIcon>
                                <ListItemText
                                    primary={text}
                                    sx={{
                                        '& .MuiTypography-root': {
                                            fontSize: '12px'
                                        }
                                    }}
                                />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>

                <Divider sx={{ my: 2 }} />

                {/* Legend Section */}
                <Typography variant="h5" gutterBottom sx={{ textAlign: 'left', pl: 0, ml: 0 }}>
                    LEGEND
                </Typography>
                <List>
                    <ListItem disablePadding>
                        <ListItemButton sx={{ pl: 1 }}>
                            <ListItemIcon sx={{ minWidth: '0px' }}>
                            </ListItemIcon>
                            <ListItemText primary="Not Yet Started"   sx={{
                                        '& .MuiTypography-root': {
                                            fontSize: '12px'
                                        }
                                    }}/>

                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton sx={{ pl: 1 }}>
                            <ListItemIcon sx={{ minWidth: '0px' }}>
                            </ListItemIcon>
                            <ListItemText primary="Complete"   sx={{
                                        '& .MuiTypography-root': {
                                            fontSize: '12px'
                                        }
                                    }}/>
                        </ListItemButton>
                    </ListItem>
                </List>
            </Box>
        </div>
    );

    return (
        <Drawer
            variant={isMobile ? 'temporary' : 'permanent'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
                keepMounted: true,
            }}
            sx={{
                display: { xs: 'block', sm: 'block' },
                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
        >
            {drawer}
        </Drawer>
    );
};

export default Sidebar;
