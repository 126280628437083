import React from 'react';
import { Box } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import Sidebar3 from '../components/Sidebar3';
import MyAppBar from '../components/MyAppBar';
import { Outlet } from 'react-router-dom';
import DynamicBreadcrumbs from '../commons/DynamicBreadcrumbs';

const drawerWidth = 240;

function SearchSystem() {
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const breadcrumbs = [
        { label: 'Home', path: '/home' },
        { label: 'Section', path: '/section' },
        { label: 'Sub-section', path: '/section/sub-section' },
    ];

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <MyAppBar handleDrawerToggle={handleDrawerToggle} />
                <Sidebar3 mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle} isMobile={isMobile} />
            </Box>
            <Box component="main" sx={{
                zIndex: (theme) => theme.zIndex.drawer + 1,
                boxShadow: 'none',
                marginTop: "7vh !important"
            }}>
                <div className='bg-gray- min-h-screen'>
                    <DynamicBreadcrumbs breadcrumbs={breadcrumbs} />
                    <Outlet />
                </div>
            </Box>
        </>
    );
}

export default SearchSystem;