import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { AuthProvider } from "./AuthContext"; // Import AuthProvider;
import PrivateRoute from "./PrivateRoute";
import Login from "./Login";
import "./App.css";
import EMASSSimulator from "./components/EMASSSimulator";
import Home from "./pages/Home";
import ResponsiveNavbar from "./pages/Home"; // Assuming you have this component
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import EditSystemInformation from "./components/EditSystemInformation";
import Authorization from "./components/Authorization";
import EditRoles from "./components/EditRoles";
import ReviewAndSubmit from "./components/ReviewAndSubmit";
import Welcome from "./components/Welcome";
import SearchSystem from "./pages/SearchSystem";
import SystemsTable from "./components/SystemsTable";
import Dashboard from "./components/Dashboard";
import SystemDetails from "./components/SystemDetails";
import Categorization from "./components/Categorization";
import InformationTypes from "./components/InformationTypes";
import PrimarySecurityControlSet from "./components/PrimarySecurityControlSet";
import ControlListing from "./components/ControlListing";

// Optional: Customize your theme here
const theme = createTheme({
  palette: {
    primary: {
      main: "#1f2937",
    },
    secondary: {
      main: "#fff",
    },
  },
});

function NotFound() {
  return <h2>Page not found</h2>;
}

function App() {
  return (
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <div className="App">
            <Routes>
              <Route path="/login" element={<Login />} />

              {/* Protected routes */}
              <Route element={<PrivateRoute />}>
                <Route path="/" element={<EMASSSimulator />} />
                <Route path="/home" element={<Home />}>
                  <Route index element={<Welcome />} />
                  <Route path="edit-system" element={<EditSystemInformation />} />
                  <Route path="auth-info" element={<Authorization />} />
                  <Route path="roles" element={<EditRoles />} />
                  <Route path="review" element={<ReviewAndSubmit />} />
                </Route>
                <Route path="/search" element={<SearchSystem />}>
                  <Route index element={<SystemsTable />} />
                  <Route path="dashboard" element={<Dashboard />} />
                  <Route path="system-details" element={<SystemDetails />} />
                  <Route path="categorization" element={<Categorization />} />
                  <Route path="information-types" element={<InformationTypes />} />
                  <Route path="primary-security" element={<PrimarySecurityControlSet />} />
                  <Route path="control-listing" element={<ControlListing />} />
                </Route>
              </Route>

              {/* Catch-all route for undefined paths */}
              <Route path="*" element={<NotFound />} />
            </Routes>
          </div>
        </Router>
      </ThemeProvider>
    </AuthProvider>
  );
}

export default App;
